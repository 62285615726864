<template>
  <DetailsAccordion
    title="Parcelles"
    :adresse="adresse"
    :active_first_accordion_item="active_first_accordion_item"
  >
    <template #right-side>
      <ProSelect
        class="md:hidden"
        :args="['label']"
        :data="list_parcelles"
        @valueSelect="setDropdownParcelleSelected"
        v-model="dropdown_parcelle_selected"
        :is_top="true"
      />
    </template>
    <template #body>
      <ProSelect
        class="hidden md:block mt-4"
        :args="['label']"
        :data="list_parcelles"
        @valueSelect="setDropdownParcelleSelected"
        v-model="dropdown_parcelle_selected"
      />
      <div class="grid grid-cols-3 gap-4 py-8 text-sm">
        <div class="sm:col-span-3">
          <div class="flex space-x-2">
            <pro-icon name="ref-cadastrale" width="19px" height="27px" />
            <div class="pt-[2px]">
              <p>Réf cadastrale</p>
              <p class="font-normal break-all">
                {{
                  parcelles_selected
                    .filter((parcelle) => parcelle)
                    .map((parcelle) => parcelle.properties.ref_cadastrale)
                    .join(', ')
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="sm:col-span-3">
          <div class="flex space-x-2">
            <pro-icon name="surface" width="22px" height="22px" />
            <div class="pt-[2px]">
              <p>Surf. totale cadastrale</p>
              <p
                v-if="SumSurfaceCadastrale(parcelles_selected) > 0"
                class="font-normal"
              >
                {{ SumSurfaceCadastrale(parcelles_selected) | roundDouble }}
                M²
              </p>
              <p v-else class="font-normal">NC</p>
            </div>
          </div>
        </div>
        <div class="sm:col-span-3">
          <div class="flex space-x-2">
            <pro-icon name="surface" width="22px" height="22px" />
            <div class="pt-[2px]">
              <p>Surf. total mesurée</p>
              <p class="font-normal">
                {{ SumSurfaceMesure(parcelles_selected) | roundDouble }}
                M²
              </p>
            </div>
          </div>
        </div>
        <div class="sm:col-span-3">
          <div class="flex space-x-2">
            <pro-icon name="surface" width="22px" height="22px" />
            <div class="pt-[2px]">
              <p>Surf. totale libre</p>
              <p class="font-normal">
                <template v-if="checkSurfaceLibreValide(parcelles_selected)">
                  {{ SumSurfaceLibre(parcelles_selected) | roundDouble }}
                  M²
                </template>
                <template v-else>Donnée publique imprécise</template>
              </p>
            </div>
          </div>
        </div>
        <div class="sm:col-span-3" v-if="getPinel()">
          <div class="flex space-x-2">
            <pro-icon name="zone-pinel" width="20px" height="21px" />
            <div class="pt-[2px]">
              <p>Pinel</p>
              <p class="font-normal">
                {{ getPinel() }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </DetailsAccordion>
</template>
<script>
import { mapGetters } from 'vuex'
import parcelle from '@/mixins/parcelle'
import batiment from '@/mixins/batiment'
import Mapbox from '@/mixins/map/mapbox'

export default {
  props: {
    adresse: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      dropdown_parcelle_selected: null,
      list_parcelles: [],
      temp_parcelles_selected: [],
      active_first_accordion_item: false,
      is_clicked_on_dropdown_parcele: false,
    }
  },

  mixins: [parcelle, batiment, Mapbox],
  computed: {
    ...mapGetters({
      features_autour: 'parcelle/features_autour',
      parcelles_autour: 'parcelle/parcelles_autour',
      current_parcelle: 'parcelle/current_parcelle',
      parcelles_voisines: 'parcelle/parcelles_voisines',
      map: 'map/map',
      current_batiments: 'parcelle/current_batiments',
      infos_commune: 'address/infos_commune',
    }),
  },
  watch: {
    dropdown_parcelle_selected: {
      deep: true,
      handler() {
        this.temp_parcelles_selected =
          this.dropdown_parcelle_selected.index === -1
            ? this.own_parcelles
            : [this.own_parcelles[this.dropdown_parcelle_selected.index]]
        this.addLayerCurrentParcelle(
          this.own_parcelles[this.dropdown_parcelle_selected.index],
        )
        if (this.is_clicked_on_dropdown_parcele) {
          let fc_parcelles = this.$turf.featureCollection(
            this.temp_parcelles_selected,
          )
          this.fitBoundsGeojsonInMap(fc_parcelles)
          this.is_clicked_on_dropdown_parcele = false
        }
      },
    },
    own_parcelles: {
      deep: true,
      handler() {
        this.init()
      },
    },
  },
  created() {
    this.init()
    if (window.innerWidth > 768) this.active_first_accordion_item = true
  },

  methods: {
    getPinel() {
      return this.getNestedObject(
        this.infos_commune.donnees_statistiques,
        'zonage_Pinel',
        'zone_loi_Pinel',
        'valeur',
      )
    },
    setDropdownParcelleSelected($event) {
      this.is_clicked_on_dropdown_parcele = true
      this.dropdown_parcelle_selected = $event
    },
    init() {
      this.list_parcelles = [
        {
          label: `Toutes les parcelles (${this.own_parcelles.length})`,
          index: -1,
        },
      ]
      this.dropdown_parcelle_selected = this.list_parcelles[0]
      if (this.own_parcelles.length > 1) {
        this.own_parcelles.forEach((parcelle, index) => {
          this.list_parcelles.push({
            label: parcelle.properties.ref_cadastrale,
            index: index,
          })
        })
      }
    },
    addLayerCurrentParcelle(parcelle) {
      this.removeSourceAndLayers(...this.source_and_layers_parcelles_voisines)
      if (this.dropdown_parcelle_selected.index != -1) {
        this.addSourceLayerToSelectedPolygone(
          parcelle,
          this.source_and_layers_parcelles_voisines,
        )
      }
    },
  },
}
</script>
